import Vue, {VueConstructor} from "@fwk-node-modules/vue";
import { Store } from '@fwk-node-modules/vuex';
import VueI18n from "@fwk-node-modules/vue-i18n";
import VueRouter from "@fwk-node-modules/vue-router";

import { createApplicationStore, types as applicationTypes } from "./store/application";
import { createAuthenticationStore } from "@fwk-client/store/authentication";
import { languagesTypes } from "@fwk-client/store/types";
import { createLayoutStore } from "./store/layout";
import { createEurofiscalisStore } from "./store/eurofiscalis";

// We import Bootstrap-Vue components
import { BDropdown, BNavItemDropdown, BNavItem, BNav, BNavbarBrand, BNavbar, BCol, 
  BRow, BContainer, BDropdownItem, BDropdownDivider, BTable, BModal, BFormFile, BTabs, BTab, BCollapse, BPagination } from 'bootstrap-vue';

// @ts-ignore
import PrettyCheckbox from 'pretty-checkbox-vue';

import vSelect from 'vue-select';

import ShopPlugin from "@fwk-client/modules/shop";
import { hooks } from '@fwk-client/interfaces/hooks';

// We import the bootstrap 4 style
// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// We import pretty checkbox style
import './theme/pretty-checkbox.scss';

// We import the metismenu style
import 'metismenu/dist/metisMenu.min.css';

// We import the ladda style
import 'ladda/dist/ladda-themeless.min.css';

// We import the vue-select style
import 'vue-select/dist/vue-select.css';

// We import the intl-tel-input stype
import 'intl-tel-input/build/css/intlTelInput.css';

// We import the toastr style
import 'toastr/toastr.scss';

// We import the vue2 daterange picker css
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

// @ts-ignore
import VueAutosuggest from "vue-autosuggest";

// We import Vee Validate components
import {ValidationProvider,ValidationObserver, extend, configure, localize} from "vee-validate";
import { required, email } from 'vee-validate/dist/rules';
import { createHospitalityStore } from "./modules/hospitality/store";
import { createAmazonStore } from "./modules/amazon/store";
import { createShopStore } from "./modules/shop/store";
import { createVatValidationStore } from "./modules/vat-validation/store";
import { createIntrastatsStore } from "./modules/intrastats/store";
import { createCompanyStore } from "./modules/company/store";

/**
 * onCreateAppEnter
 * This method is called first when we enter the createApp method of the client.ts
 * @param Vue 
 * @param initialState 
 */
hooks.onCreateAppEnter = function (Vue:VueConstructor<Vue>, initialState:any) {

  // We import the v-b-scrollspy component
  Vue.component('b-nav-item-dropdown', BNavItemDropdown);
  Vue.component('b-dropdown', BDropdown);
  Vue.component('b-dropdown-item', BDropdownItem);
  Vue.component('b-table', BTable);
  Vue.component('b-pagination', BPagination);
  Vue.component('b-modal', BModal);
  Vue.component('b-form-file', BFormFile);
  Vue.component('b-tabs', BTabs);
  Vue.component('b-tab', BTab);
  Vue.component('b-collapse', BCollapse);

  // @ts-ignore
  Vue.component('v-select', vSelect);

  Vue.use(PrettyCheckbox);
  Vue.use(VueAutosuggest);

  // We add the common validaton and component for Vee Validate
  extend('required',required);
  extend('email',email);
  Vue.component('validation-provider', ValidationProvider);
  Vue.component('validation-observer', ValidationObserver);
}

/**
 * onStoreCreated
 * This method is called when the store is created within store/index.ts
 * @param store - the store instance just created.
 */
hooks.onStoreCreated = function(store: Store<any>) {

  // We register a module in the store
  var applicationStore = createApplicationStore();
  store.registerModule("application", applicationStore);

  // We register a module in the store
  var hospitalityStore = createHospitalityStore();
  store.registerModule("hospitality", hospitalityStore);

  // We register a module in the store
  var shopStore = createShopStore();
  store.registerModule("shop", shopStore);

  // We register a module in the store
  var amazonStore = createAmazonStore();
  store.registerModule("amazon", amazonStore);

  // We register a module in the store
  var vatValidationStore = createVatValidationStore();
  store.registerModule("vat-validation", vatValidationStore);

  // We register a module in the store
  var intrastatsStore = createIntrastatsStore();
  store.registerModule("intrastats", intrastatsStore);

  // We register a module in the store
  var companyStore = createCompanyStore();
  store.registerModule("company", companyStore);

  // We register the layout store
  var layoutStore = createLayoutStore();
  store.registerModule("layout", layoutStore);

  // We register the authentication module in the store
  var authenticationStore = createAuthenticationStore();
  store.registerModule("authentication", authenticationStore);

  // We register the eurofiscalis module in the store
  var eurofiscalisStore = createEurofiscalisStore();
  store.registerModule("eurofiscalis", eurofiscalisStore);
}

/**
 * onStorePopulated
 * This method is called when the stores are populated within store/index.ts
 * It is called when there is no initial state from SSR.
 * @param store - the store instance to be populated.
 */
export function onStorePopulated (store: Store<any>) {
  // We add a method for language store after language is set to set the local of VeeValidate
  store.commit('languages/' + languagesTypes.mutations.ADD_AFTER_SET_LANGUAGE_ACTION, {action: 'application/' + applicationTypes.actions.UPDATE_LANGUAGE});
}

/**
 * onVueDependenciesCreated
 * This method is called when all dependencies are created (store, router, i18n), before creating the Vue instance.
 * This method is called in client.ts.
 * @param store 
 * @param router 
 * @param i18n 
 * @param initialState 
 */
hooks.onVueDependenciesCreated = function(store: Store<any>, router: VueRouter, i18n: VueI18n, initialState: any) {
  
  // We add the shop plugin
  Vue.use(ShopPlugin);
  
  // We call the localize method initially
  // This is done as when called the first time, defaultMessage method is configured as well.
  // https://github.com/logaretm/vee-validate/issues/2426
  localize('en');

  configure({
    /* 
    classes: {
      valid: 'has-success',
      invalid: 'has-error'
    }
    */
    classes: {
      valid: 'is-valid has-success',
      invalid: 'is-invalid has-error'
    },
    defaultMessage: function(_, values) {
      return i18n.t(`error.fields.${values!._rule_}`, values) as string
    }
  });

  if(store.state.languages.currentLanguageCode != null) {
    // We localize vee validate. The language code is available here on client side only.
    var languageCode = store.state.languages.currentLanguageCode;
    localize(languageCode);
  }
}

// We export the updated hooks
export { hooks };
