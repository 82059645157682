import { Module } from '@fwk-node-modules/vuex';

export const types = {
  getters : {
    GET_USER_MODULES : 'GET_USER_MODULES',
    GET_USER_SELECTED_MODULE : 'GET_USER_SELECTED_MODULE',
    GET_USER_SELECTED_COMPANY : 'GET_USER_SELECTED_COMPANY'
  },
  mutations : {
    SET_USER_MODULES : 'SET_USER_MODULES',
    SET_USER_SELECTED_MODULE : 'SET_USER_SELECTED_MODULE',
    SET_USER_SELECTED_COMPANY : 'SET_USER_SELECTED_COMPANY'
  },
  actions : {
    
  }
}

export function createCompanyStore () {

  const store:Module<any, any> = {
    namespaced: true,
    state: {
      userModules : [], // We store the modules associated to the user on the target DB.
      userSelectedModule : null, // We store the production module associated to the user on the target DB.
      userSelectedCompany : null, // We store the module associated company
    },
    getters: {
      // List of admin elements
      [types.getters.GET_USER_MODULES] (state):any { return state.userModules; },
      [types.getters.GET_USER_SELECTED_COMPANY] (state):any { return state.userSelectedCompany; },

      // Selected admin element
      [types.getters.GET_USER_SELECTED_MODULE] (state):any { return state.userSelectedModule; }
    },
    mutations: {
      [types.mutations.SET_USER_MODULES] (state, modules) { state.userModules = modules; },
      [types.mutations.SET_USER_SELECTED_MODULE] (state, module) { state.userSelectedModule = module; },
      [types.mutations.SET_USER_SELECTED_COMPANY] (state, company) { state.userSelectedCompany = company; },
    },
    actions: {
    }
    
  }
  return store;
};