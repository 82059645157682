
import { PAGE_NOT_FOUND } from '@fwk-client/router/utils';
import { RouteConfig } from '@fwk-node-modules/vue-router';
import { utils } from '@fwk-client/router/utils';
import { Store } from '@fwk-node-modules/vuex';
import { languagesTypes } from '@fwk-client/store/types';
import { References } from '@fwk-client/store/languages';
import { routes as authenticationRoutes } from './authentication';
import { routes as intrastatsRoutes } from '../modules/intrastats/router/routes';
import { routes as eurofiscalisRoutes } from './eurofiscalis';
import { routes as vigneronOnlineRoutes } from '../modules/vigneron-online/router/routes';
import { routes as eurofiscalisWorkersRoutes } from './eurofiscalis-workers';
import { routes as adminRoutes } from './admin';
import { routes as igotwebRoutes } from './igot-web';
import { routes as toolsRoutes } from './tools';
import { routes as shopRoutes } from '../modules/shop/router/routes';
import { routes as cmsRoutes } from '../modules/cms/router/routes';
import { routes as blogRoutes } from '../modules/blog/router/routes';
import { routes as hospitalityRoutes } from '../modules/hospitality/router/routes';
import { routes as amazonRoutes } from '../modules/amazon/router/routes';
import { routes as vatValidationRoutes } from '../modules/vat-validation/router/routes';
import { routes as companyRoutes } from '../modules/company/router/routes';
import { roles } from '@igotweb/core-api/src/roles';


/*
 * We use Lazy Loading to have each route loaded asynchronously from the main bundle in the page.
 * Note that to support this syntax with Babel, we need to use syntax-dynamic-import plugin for babel.
 */
const Home = () => import(/* webpackChunkName: "route-home" */ '../components/pages/Home.vue').then(m => m.default);
const Profile = () => import(/* webpackChunkName: "route-profile" */ '../components/pages/Profile.vue').then(m => m.default);
const Configuration = () => import(/* webpackChunkName: "route-configuration" */ '../components/pages/Configuration.vue').then(m => m.default);
const PageNotFound = () => import(/* webpackChunkName: "route-pageNotFound" */ '../components/pages/PageNotFound.vue').then(m => m.default);
const Maintenance = () => import(/* webpackChunkName: "route-maintenance" */ '../components/pages/Maintenance.vue').then(m => m.default);
const AccessRestricted = () => import(/* webpackChunkName: "route-access-restricted" */ '../components/pages/AccessRestricted.vue').then(m => m.default);

const Storage = () => import(/* webpackChunkName: "route-module-storage" */ '../components/modules/Storage.vue').then(m => m.default);

const routes = [
  { path: '/', redirect: '/home' }, // When user target root path, we redirect it to the home path.
  {
    path: '/home',
    component: Home,
    meta: {
      layout: "Portal",
      authRoles: [roles.user],
      scrollToTop: true,
      languageBundle: ['home','company','profile','eurofiscalis_workers','invoice','eurofiscalis_company'],
      languageReferences: [References.COUNTRIES]
    }
  },
  {
    path: '/profile',
    component: Profile,
    meta: {
      layout: "Portal",
      authRoles: [roles.user],
      scrollToTop: true,
      languageBundle: 'profile'
    }
  },
  ...authenticationRoutes,
  ...igotwebRoutes,
  ...eurofiscalisRoutes,
  ...eurofiscalisWorkersRoutes,
  ...vigneronOnlineRoutes,
  ...adminRoutes,
  ...toolsRoutes,
  ...shopRoutes,
  ...cmsRoutes,
  ...blogRoutes,
  ...hospitalityRoutes,
  ...amazonRoutes,
  ...vatValidationRoutes,
  ...intrastatsRoutes,
  ...companyRoutes,
  {
    path: '/storage',
    component: Storage,
    meta: {
      layout: "Portal",
      authRoles: [roles.user],
      scrollToTop: true
    }
  },
  {
    path: '/configuration',
    component: Configuration,
    meta: {
      layout: "Portal",
      authRoles: [roles.user],
      scrollToTop: true,
      languageBundle: ['configuration', 'shop']
    }
  },
  { 
    // The default route is used to catch all urls redirecting to non existing routes.
    path: '/maintenance', 
    component: Maintenance,
    meta: {
      bodyClasses: ['gray-bg']
    }
  },
  { 
    // The default route is used to catch all urls redirecting to non existing routes.
    path: '/access-restricted', 
    component: AccessRestricted,
    meta: {
      bodyClasses: ['gray-bg']
    }
  },
  { 
    // The default route is used to catch all urls redirecting to non existing routes.
    path: '*', 
    component: PageNotFound,
    meta: {
      bodyClasses: ['gray-bg'],
      [PAGE_NOT_FOUND]: true
    }
  } 
];


// We export all the routes
export function getRoutes(store:Store<any>):Array<RouteConfig> {
  // We update the routes to add localized version
  const supportedLanguages:string[] = store.getters['languages/' + languagesTypes.getters.GET_AVAILABLE_LANGUAGES]
  return utils.addLocalizedRoutes(routes, supportedLanguages);
}