import { roles } from '@igotweb-node-api/api/modules/company/src/roles';
import { roles as apiRoles } from '@igotweb/core-api/src/roles';
import { References } from '@fwk-client/store/languages';

const Company = () => import(/* webpackChunkName: "route-module-company" */ '../components/pages/Company.vue').then(m => m.default);
const AdminCompany = () => import(/* webpackChunkName: "route-module-admin-company" */ '../components/pages/admin/Companies.vue').then(m => m.default);

export const routes = [
  {
    path: '/company',
    component: Company,
    meta: {
      layout: "Portal",
      languageBundle: ['home','company','profile','eurofiscalis_workers','invoice'],
      languageReferences: [References.COUNTRIES],
      authRoles: [roles.company_admin],
      scrollToTop: true
    }
  },
  {
    path: '/admin/company',
    component: AdminCompany,
    meta: {
      layout: "Portal",
      authRoles: [apiRoles.superadmin],
      scrollToTop: true,
      languageBundle: ['admin_companies','company'],
    }
  },
]