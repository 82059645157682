import { roles } from '@igotweb-node-api/api/modules/intrastats/src/roles';

const IntrastatsCheck = () => import(/* webpackChunkName: "route-module-intrastats-check" */ '../components/pages/Check.vue').then(m => m.default);
const IntrastatsFiles = () => import(/* webpackChunkName: "route-module-intrastats-files" */ '../components/pages/Files.vue').then(m => m.default);
const IntrastatsGenerate = () => import(/* webpackChunkName: "route-module-intrastats-generate" */ '../components/pages/Generate.vue').then(m => m.default);

const AdminIntrastats = () => import(/* webpackChunkName: "route-module-admin-intrastats" */ '../components/pages/admin/Modules.vue').then(m => m.default);

export const routes = [
  {
    path: '/intrastats/check',
    component: IntrastatsCheck,
    meta: {
      layout: "Portal",
      languageBundle: 'intrastats',
      authRoles: [roles.intrastats_guest],
      scrollToTop: true
    }
  },
  {
    path: '/intrastats/files',
    component: IntrastatsFiles,
    meta: {
      layout: "Portal",
      languageBundle: 'intrastats',
      authRoles: [roles.intrastats_admin],
      scrollToTop: true
    }
  },
  {
    path: '/intrastats/generate',
    component: IntrastatsGenerate,
    meta: {
      layout: "Portal",
      languageBundle: 'intrastats',
      authRoles: [roles.intrastats_admin],
      scrollToTop: true
    }
  },
  {
    path: '/admin/intrastats',
    component: AdminIntrastats,
    meta: {
      layout: "Portal",
      authRoles: ['user'],
      scrollToTop: true,
      languageBundle: ['intrastats'],
    }
  },
]