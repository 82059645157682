import { Module } from '@fwk-node-modules/vuex';
import { GenericStoreModuleTypes } from '@root/src/client/composables/admin/useGenericModuleAdmin';

export const types = {
  getters : {
    GET_USER_MODULES : 'GET_USER_MODULES',
    GET_USER_SELECTED_MODULE : 'GET_USER_SELECTED_MODULE'
  },
  mutations : {
    SET_USER_MODULES : 'SET_USER_MODULES',
    SET_USER_SELECTED_MODULE : 'SET_USER_SELECTED_MODULE'
  },
  actions : {
    
  }
}

export function createIntrastatsStore () {

  const store:Module<any, any> = {
    namespaced: true,
    state: {
      userModules : [], // We store the modules associated to the user on the target DB.
      userSelectedModule : null, // We store the production module associated to the user on the target DB.
    },
    getters: {
      // List of admin elements
      [types.getters.GET_USER_MODULES] (state):any { return state.userModules; },

      // Selected admin element
      [types.getters.GET_USER_SELECTED_MODULE] (state):any { return state.userSelectedModule; }
    },
    mutations: {
      [types.mutations.SET_USER_MODULES] (state, modules) { state.userModules = modules; },
      [types.mutations.SET_USER_SELECTED_MODULE] (state, module) { state.userSelectedModule = module; }
    },
    actions: {
    }
    
  }
  return store;
};